export const Tooltip = ({ content, children }) => {
  const tooltipWrapper = 'relative inline-block cursor-pointer [&>div]:hover:visible h-0';
  const tooltipContent =
    'invisible w-56 bg-snow text-rain text-sm border border-dotted border-rain shadow-crisp text-center rounded-6 p-8 absolute left-8 -top-2 z-10';

  return (
    <div className={tooltipWrapper}>
      {children}
      <div className={tooltipContent}>{content}</div>
    </div>
  );
};
