import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAboutUsModal } from '../actions/modals';
import { LanguageToggle } from './general/LanguageToggle';
import { Translate } from './general/Translate';
import dripFullLogo from '../assets/drip-full-logo.svg';
import tamuLogo from '../assets/tamu-logo.png';
import questionMarkIcon from '../assets/icons/question-mark.svg';
import { Button } from './general/Button';

export const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mobileView, setMobileView] = useState(window.innerWidth < 850);

  window.addEventListener('resize', () => {
    setMobileView(window.innerWidth < 850);
  });

  const backgroundImage =
    'bg-waves-image bg-cover bg-center min-h-screen flex flex-col justify-center items-center';
  const contentContainer =
    'flex flex-col items-center rounded-xl border-2 border-solid border-rain bg-ice p-6 md:p-8';
  const logoSize = 'h-16 w-[8.5em] md:h-28 md:w-60';

  return (
    <div className={backgroundImage}>
      <LanguageToggle />
      <div className={contentContainer}>
        {mobileView && (
          <button onClick={() => dispatch(setAboutUsModal(true))} className="flex h-4 self-end">
            <img src={questionMarkIcon} alt="DRIP Logo" />
          </button>
        )}
        <div className="mb-8 mt-4 flex md:mt-0 md:gap-5">
          <img
            src={tamuLogo}
            alt="Texas A&M University Logo"
            className="w-[11.25em] md:h-28 md:w-80"
          />
          <img src={dripFullLogo} alt="DRIP Logo" className={logoSize} />
        </div>
        <h1 className="mb-14 w-72 text-center text-4xl font-bold text-rain md:mb-10 md:w-full">
          Community Driven Flood Map
        </h1>
        {!mobileView && (
          <div className="mb-10 w-[500px] text-center text-rain">
            <Translate id="sitePurposeCopy" className="mb-5" />
            <Translate id="dripMissionCopy" />
          </div>
        )}
        <Button onClick={() => navigate('/upload')} className="w-52 md:w-80">
          <Translate id="uploadImages" />
        </Button>
      </div>
    </div>
  );
};
