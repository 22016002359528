import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_ROOT = '/api';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    // Add endpoints here
    // See: https://redux-toolkit.js.org/rtk-query/usage/queries
    checkImageDuplicate: build.query({
      query: ({ imageName, imageDateTime }) => ({
        url: `/property/check-image-duplicate?imageName=${imageName}&imageDateTime=${imageDateTime}`,
      }),
    }),
    uploadImageAndJSON: build.mutation({
      query: imageFile => ({
        url: '/property/upload-image-and-data',
        method: 'POST',
        body: imageFile,
      }),
    }),
  }),
});

// Given a set of coordinates, this hook will return the corresponding US Census location (e.g. Boston city)
export const getCensusLocationFromCoordinates = async coordinates => {
  const response = await fetch(
    `${API_ROOT}/property/location-from-point?longitude=${coordinates[0]}&latitude=${coordinates[1]}`
  );
  const data = await response.json();
  return data.location;
};

export const { useUploadImageAndJSONMutation, useLazyCheckImageDuplicateQuery } = api;
