import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageCode } from '../../actions/translations';
import { languageCodeSelector } from '../../selectors/translations';

export const LanguageToggle = () => {
  const dispatch = useDispatch();
  const activeLanguageCode = useSelector(languageCodeSelector);
  const inEnglishMode = activeLanguageCode != null && activeLanguageCode === 'en';

  const languageButton = clsx(
    'shadow-crisp fixed right-0 top-8 z-10 h-12 w-24 rounded-e-none rounded-s-xl border-y-2 border-l-2 border-solid border-ice bg-rain py-2 pl-1 text-lg font-semibold capitalize text-ice transition-colors duration-200 ease-in hover:bg-rain-light md:h-16 md:w-[110px] md:py-4'
  );

  const handleClick = () => {
    dispatch(setLanguageCode(inEnglishMode ? 'es' : 'en'));
  };

  return (
    <button className={languageButton} onClick={handleClick}>
      {inEnglishMode ? 'Español' : 'English'}
    </button>
  );
};
