import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Translate } from './general/Translate';
import { Button } from './general/Button';
import { languageCodeSelector } from '../selectors/translations';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { validateMandatoryFields } from '../validators';
import { getDateFromDateTime, getTimeFromDateTime } from '../utils';
import {
  multiFieldLine,
  OptionsList,
  ParagraphTextField,
  SearchField,
  SelectField,
  TextField,
  AppearanceReleaseField,
} from './FormFields';
import {
  LOCATION_ID,
  DATE_ID,
  HOUR_ID,
  MINUTE_ID,
  AM_PM_ID,
  AM_PM_OPTIONS,
  ASSET_TYPES_ID,
  ASSET_TYPES_OPTIONS,
  MULTISELECT_STYLE,
  DAMAGE_TYPES_OPTIONS,
  DAMAGE_ADDITIONAL_INFO_ID,
  FLOOD_DEPTH_ID,
  FLOOD_DAMAGE_ID,
  HAS_FLOODED_BEFORE_ID,
  FLOOD_EVENT_DESCRIPTION_ID,
  HAS_FLOODED_BEFORE_OPTIONS,
  APPEARANCE_RELEASE,
} from '../constants/formField';
import { Tooltip } from './general/Tooltip';
import infoIcon from '../assets/icons/info.svg';
import { setHistoricalImages } from '../actions/general';
import { getCensusLocationFromCoordinates, useUploadImageAndJSONMutation } from '../services/api';
import { errorToast, successToast } from '../utils/toast';
import Select from 'react-select';

export const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeLanguageCode = useSelector(languageCodeSelector);

  const [currentImage, setCurrentImage] = useState(null);

  const [uploadImageAndJSON, { isLoading, isSuccess, isError }] = useUploadImageAndJSONMutation();

  let coordinates = null;
  let hasDateTime = false;
  let imageDate = null;
  let imageHour = null;
  let imageMinutes = null;
  let imageAMPM = null;

  const inputRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    if (!imagesArray) {
      navigate('/');
    }

    const initPlaces = async () => {
      await window.google.maps.importLibrary('places');

      const options = {
        bounds: {
          north: 36.55,
          south: 25.55,
          east: -93.3,
          west: -106.7,
        },
        componentRestrictions: { country: 'us' },
        fields: ['formatted_address', 'geometry.location'],
        strictBounds: true,
        types: ['address'],
      };

      // Adding this extra check to avoid the error "InvalidValueError: not an instance of HTMLInputElement"
      if (inputRef.current) {
        // Google Autocomplete source: https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          const { formatted_address, geometry } = place;

          // If a user just hits return in the search without selecting an autocomplete option,
          // we don't get any information (the component doesn't even hit the endpoint). Just
          // return in this case and force the user to select from the dropdown
          if (!formatted_address || !geometry) {
            return;
          }
          const { location } = geometry;
          const newCoordinates = [location.lng(), location.lat()];

          // Setting the Formik value to the address coordinates and getting rid of the error
          // Source: https://stackoverflow.com/questions/60358836/how-to-formik-setfieldvalue-in-useeffect-hook
          formikRef.current.setFieldValue('locationCoordinates', newCoordinates);
          formikRef.current.setFieldError('locationCoordinates', null);
        });
      }
    };

    initPlaces();
  }, []);

  const imagesArray = location.state?.imagesArray;

  if (!imagesArray) {
    return null;
  }

  // Checking that at least one image in the array has coordinates and date time
  imagesArray.forEach(image => {
    if (coordinates && hasDateTime) {
      return;
    }
    if (!coordinates && image.coordinates) {
      coordinates = image.coordinates;
    }
    if (!hasDateTime && image.imageDateTime) {
      hasDateTime = true;

      // Get the image date in format YYYY-MM-DD
      imageDate = getDateFromDateTime(image.imageDateTime);

      // Get the image hour, minutes and AM/PM in a 12-hour format
      const [hour, minutes, amPM] = getTimeFromDateTime(image.imageDateTime);
      imageHour = hour;
      imageMinutes = minutes;
      imageAMPM = amPM;
    }
  });

  const invertedButtonStyle =
    '!bg-snow !text-rain border-2 border-solid border-rain hover:!bg-ice w-32';

  const cancelUpload = () => {
    const message =
      activeLanguageCode === 'en'
        ? 'Are you sure you want to cancel the upload?'
        : '¿Quiere cancelar el envio de imágenes?';
    const confirmedCancel = window.confirm(message);
    confirmedCancel && navigate('/');
  };

  const uploadPictures = async values => {
    const coordinates = values.locationCoordinates;
    // Retrieving the US Census location from the coordinates
    const location = await getCensusLocationFromCoordinates(coordinates);

    // Converting it to a float instead of string
    const floodDepth = parseFloat(values.floodDepth);

    // Standardizing manual date time to ISO format
    const dateParts = values.date.split('-');
    const newDate = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];
    const newHour = values.hour + (!values.isAM && values.hour !== 12 && 12);
    const newTime = newHour + ':' + values.minute + ':00';
    const manualDateTime = new Date(newDate + ' ' + newTime).toISOString();

    // Convert image files to URLs and add them to historical array of pictures
    const imagesURLs = imagesArray.map(image => ({
      name: image.imageName,
      url: URL.createObjectURL(image.image),
    }));
    dispatch(setHistoricalImages(imagesURLs));

    for (const image of imagesArray) {
      const finalLocation = location || 'UNKNOWN';
      const finalEvent = values.floodEventDescription || 'UNIDENTIFIED';
      const finalEventDateTime = image.imageDateTime.toISOString() || manualDateTime;
      const imageType = image.imageName.split('.')[1].toLowerCase();

      setCurrentImage(image.imageName);

      const imageData = {
        originalName: image.imageName,
        processedName: `${finalLocation}-${finalEvent}-${finalEventDateTime}.${imageType}`,
        location: finalLocation,
        coordinates: image.coordinates || coordinates,
        eventDateTime: finalEventDateTime,
        floodEventDescription: finalEvent,
        assetType:
          ['Select', 'Selecciona'].indexOf(values.assetType) === -1 ? values.assetType : null,
        damageType: values.damageType.length > 0 ? values.damageType : null,
        damageAdditionalInfo: values.damageAdditionalInfo || null,
        hasFloodedBefore:
          ['Select', 'Selecciona'].indexOf(values.hasFloodedBefore) === -1
            ? values.hasFloodedBefore
            : null,
        estFloodDepth: floodDepth || null,
        estFloodDamage: values.floodDamage || null,
      };

      let imageFile = new FormData();
      imageFile.append('image', image.image);
      imageFile.append('imageData', JSON.stringify(imageData));
      await uploadImageAndJSON(imageFile);
    }

    // Go back to the Upload page to continue uploading more images
    navigate('/upload');
  };

  isSuccess && successToast(`${currentImage} was successfully uploaded!`, currentImage);

  isError &&
    errorToast(`There was an error uploading ${currentImage}. Please try again.`, currentImage);

  return (
    <Formik
      innerRef={formikRef}
      validate={validateMandatoryFields}
      initialValues={{
        locationCoordinates: coordinates || [],
        date: imageDate || '',
        hour: imageHour || '',
        minute: imageMinutes || '',
        isAM: imageAMPM === 'AM' ? true : false,
        assetType: activeLanguageCode === 'en' ? 'Select' : 'Selecciona',
        damageType: [],
        damageAdditionalInfo: '',
        floodDepth: '',
        floodDamage: '',
        hasFloodedBefore: activeLanguageCode === 'en' ? 'Select' : 'Selecciona',
        floodEventDescription: '',
        appearanceRelease: false,
      }}
      onSubmit={uploadPictures}
    >
      {({ handleSubmit, errors, values, touched, submitCount }) => {
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];

        // When a user makes changes in the input box (an autofill address is edited), it resets the coordinates and triggers the error
        const handleLocationChange = e => {
          formikRef.current.setFieldValue('locationCoordinates', []); // Reset coordinates
          formikRef.current.setFieldError('locationCoordinates', <Translate id="required" />); // Triggers error
        };

        return (
          <div className="flex flex-col items-center justify-center">
            <Translate
              id="floodInfo"
              className="my-10 w-72 text-center text-3xl font-bold text-rain md:w-full md:text-4xl"
            />
            {/* Location */}
            {!coordinates && (
              <>
                <SearchField
                  name={LOCATION_ID}
                  label={LOCATION_ID}
                  inputRef={inputRef}
                  onChange={handleLocationChange}
                  placeholder={
                    activeLanguageCode === 'en'
                      ? 'Enter your address...'
                      : 'Introduzca su dirección...'
                  }
                  error={getError('locationCoordinates')}
                />
              </>
            )}

            {/* Date time */}
            {!hasDateTime && (
              <>
                <div className={clsx(multiFieldLine, 'w-80 md:w-[500px]')}>
                  <TextField
                    name={DATE_ID}
                    label={DATE_ID}
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    error={getError('date')}
                    additionalInputBoxStyling="[&>input]:!w-40"
                  />
                  <div className="flex flex-row gap-8">
                    <TextField
                      name={HOUR_ID}
                      label={HOUR_ID}
                      type="number"
                      min="1"
                      max="12"
                      error={getError('hour')}
                      additionalInputBoxStyling="!self-start [&>input]:!w-20"
                    />
                    <TextField
                      name={MINUTE_ID}
                      label={MINUTE_ID}
                      type="number"
                      min="0"
                      max="59"
                      error={getError('minute')}
                      additionalInputBoxStyling="!self-start [&>input]:!w-20"
                    />
                    <SelectField name={AM_PM_ID} label={AM_PM_ID} additionalStyling="!w-[83px]">
                      <OptionsList
                        options={AM_PM_OPTIONS}
                        activeLanguageCode={activeLanguageCode}
                      />
                    </SelectField>
                  </div>
                </div>
              </>
            )}

            {/* Asset Type */}
            <div className="flex md:w-[500px]">
              <SelectField
                label={ASSET_TYPES_ID}
                name={ASSET_TYPES_ID}
                additionalStyling="md:!w-40"
                additionalInputBoxStyling="self-start"
              >
                <OptionsList
                  options={ASSET_TYPES_OPTIONS}
                  name={ASSET_TYPES_ID}
                  activeLanguageCode={activeLanguageCode}
                />
              </SelectField>
            </div>

            {/* Damage Type */}
            <div className="mb-5 flex w-[320px] flex-col md:w-[500px]">
              <Translate id="damageType" className="mb-2 text-rain" />
              <Select
                styles={MULTISELECT_STYLE}
                options={DAMAGE_TYPES_OPTIONS}
                isMulti
                placeholder={
                  activeLanguageCode === 'en'
                    ? 'Select one or multiple'
                    : 'Selecciona uno o múltiples'
                }
                closeMenuOnSelect={false}
                isSearchable={false}
                onChange={selectedOptions =>
                  (values.damageType = selectedOptions.map(option => option.value))
                }
              />
            </div>

            {/* Damage Additional Info */}
            <ParagraphTextField
              name={DAMAGE_ADDITIONAL_INFO_ID}
              label={DAMAGE_ADDITIONAL_INFO_ID}
              additionalStyling="h-16"
            />

            {/* Has this are flooded before? */}
            <div className="flex md:w-[500px]">
              <SelectField
                label={HAS_FLOODED_BEFORE_ID}
                name={HAS_FLOODED_BEFORE_ID}
                additionalStyling="md:!w-40"
                additionalInputBoxStyling="self-start"
              >
                <OptionsList
                  options={HAS_FLOODED_BEFORE_OPTIONS}
                  name={HAS_FLOODED_BEFORE_ID}
                  activeLanguageCode={activeLanguageCode}
                />
              </SelectField>
            </div>

            <div className={clsx(multiFieldLine, 'my-2 w-80 md:w-[500px]')}>
              <div className="flex w-full flex-col justify-start md:flex-row md:gap-7">
                {/* Est. Water Depth */}
                <div className="flex gap-2">
                  <TextField
                    name={FLOOD_DEPTH_ID}
                    label={FLOOD_DEPTH_ID}
                    type="number"
                    min="0"
                    placeholder="5"
                    error={getError('floodDepth')}
                    additionalInputBoxStyling="!self-start [&>input]:!w-24"
                  />
                  <Tooltip content={<Translate id="floodDepthTooltip" />}>
                    <img src={infoIcon} alt="Flood Depth Info" className="mt-2 self-start" />
                  </Tooltip>
                </div>
                {/* Est. Damage ($) */}
                <div className="flex gap-4">
                  <TextField
                    name={FLOOD_DAMAGE_ID}
                    label={FLOOD_DAMAGE_ID}
                    type="number"
                    min="0"
                    placeholder="500"
                    error={getError('floodDamage')}
                    additionalInputBoxStyling="!self-start [&>input]:!w-24"
                  />
                  <Tooltip content={<Translate id="floodDamageTooltip" />}>
                    <img src={infoIcon} alt="Flood Damage Info" className="mt-2 self-start" />
                  </Tooltip>
                </div>
              </div>
            </div>

            {/* Flood Event Description */}
            <ParagraphTextField
              name={FLOOD_EVENT_DESCRIPTION_ID}
              label={FLOOD_EVENT_DESCRIPTION_ID}
              maxCharacters={140}
              additionalStyling="h-32"
            />

            {/* Release Form */}
            <AppearanceReleaseField
              name={APPEARANCE_RELEASE}
              label={APPEARANCE_RELEASE}
              error={getError('appearanceRelease')}
            />

            {/* Display a general error message if there's any outstanding error after clicking the submit button */}
            {submitCount > 0 &&
              (errors.locationCoordinates ||
                errors.date ||
                errors.hour ||
                errors.minute ||
                errors.floodDepth ||
                errors.floodDamage ||
                errors.appearanceRelease) && (
                <Translate id="generalErrorMessage" className="w-80 font-bold text-error" />
              )}

            {/* Submit and Cancel Buttons */}
            <div className="my-10 flex flex-row gap-5 md:gap-10">
              <Button onClick={cancelUpload} className={invertedButtonStyle}>
                <Translate id="cancel" />
              </Button>

              <Button onClick={handleSubmit} isLoading={isLoading} className="w-48 md:w-52">
                <Translate id="submitImages" />
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
