import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate } from './general/Translate';
import imageUploadIcon from '../assets/icons/image-upload.svg';
import { Button } from './general/Button';
import { ThumbnailImages } from './ThumbnailImages';
import { ProgressBar } from './general/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { languageCodeSelector } from '../selectors/translations';
import { convertDMStoDecimal } from '../utils';
import { historicalImagesSelector } from '../selectors/general';
import { clearStore } from '../actions/general';
import { useLazyCheckImageDuplicateQuery } from '../services/api';
import { errorToast } from '../utils/toast';
import EXIF from 'exif-js';

export const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Check if user has already submitted flood images and display them in thumbnails
  const historicalImages = useSelector(historicalImagesSelector);
  const displayHistoricalImages = historicalImages.length > 0;

  const activeLanguageCode = useSelector(languageCodeSelector);
  const [checkDuplicateInBlobStorage] = useLazyCheckImageDuplicateQuery();

  const [dragActive, setDragActive] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);

  const handleImageFile = async image => {
    const imageName = image?.name;

    // Get image date time in UTC epoch and convert to local date time
    const epochTime = image?.lastModified;
    const imageDateTime = new Date(0);
    imageDateTime.setUTCSeconds(epochTime / 1000);

    // Checks if this image has already been selected and if so, it shows an error toast and exits the function
    if (imagesArray.some(image => image.imageName === imageName)) {
      errorToast(`Image ${imageName} has already been selected. Please select a different image.`);
      return;
    }

    // Checks if this image already exists in the blob storage and if so, it deletes it and shows an error toast
    try {
      const { isDuplicate } = await checkDuplicateInBlobStorage({
        imageName: imageName,
        imageDateTime: imageDateTime.toISOString(),
      }).unwrap();

      if (isDuplicate) {
        errorToast(
          `Image ${imageName} has already been uploaded. Please upload a different image.`
        );
        return;
      }
    } catch (error) {
      errorToast('There was an error uploading your image, please try again later');
      return;
    }

    let coordinates = null;
    // Extract coordinates from image EXIF metadata
    coordinates = await new Promise(resolve =>
      EXIF.getData(image, function () {
        const exifData = this.exifdata;
        if (
          exifData.GPSLatitude &&
          exifData.GPSLongitude &&
          exifData.GPSLatitudeRef &&
          exifData.GPSLongitudeRef
        ) {
          // Convert longitude/latitude degrees, minutes, seconds to decimal degrees
          const latitude = convertDMStoDecimal(exifData.GPSLatitude, exifData.GPSLatitudeRef);
          const longitude = convertDMStoDecimal(exifData.GPSLongitude, exifData.GPSLongitudeRef);
          resolve([longitude, latitude]);
        }
        resolve(null);
      })
    );

    // Get coordinates from system if not found in image EXIF metadata
    if (!coordinates) {
      const geolocationAPI = navigator.geolocation;
      if (geolocationAPI) {
        geolocationAPI.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          coordinates = [longitude, latitude];
        });
      }
    }

    // Update array of images with new image
    setImagesArray([
      ...imagesArray,
      {
        image,
        imageName,
        imageDateTime,
        coordinates,
        isLoaded: true,
      },
    ]);
  };

  // Handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // Triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageFile(e.dataTransfer.files[0]);
    }
  };

  // Triggers when file is selected
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleImageFile(e.target.files[0]);
    }
  };

  const cancelUpload = () => {
    // If there's at least an image selected, show confirmation message
    if (imagesArray.length > 0) {
      const message =
        activeLanguageCode === 'en'
          ? 'Are you sure you want to cancel the upload?'
          : '¿Quiere cancelar el envio de imágenes?';
      const confirmedCancel = window.confirm(message);
      confirmedCancel && navigate('/');
    } else {
      navigate('/');
    }
    displayHistoricalImages && dispatch(clearStore());
  };

  const uploadImageBox =
    'mt-6 mb-12 md:my-8 h-auto p-12 md:p-16 border-2 border-dashed border-rain rounded-lg bg-ice hover:bg-ice-dark';

  const invertedButtonStyle =
    '!bg-ice !text-rain border-2 border-solid border-rain hover:!bg-ice-dark w-32';

  const uploadCompleted = '!bg-sage-600 hover:!bg-sage-400 !w-[200px]';

  return (
    <div className="flex flex-col items-center justify-center">
      <Translate
        id={displayHistoricalImages ? 'uploadOtherImages' : 'uploadYourImages'}
        className="mx-5 mt-10 text-center text-3xl font-bold text-rain md:mb-6 md:text-4xl"
      />

      <div className="flex w-9/12 flex-col items-start text-rain md:w-1/2">
        {displayHistoricalImages && (
          <div className="mt-5 inline">
            <Translate id="thankYouMessage" className="inline" />
            <a
              href="mailto:idrt@tamug.edu"
              target="_blank"
              rel="noreferrer"
              className="!text-slate underline underline-offset-4"
            >
              idrt@tamug.edu
            </a>
          </div>
        )}
        <Translate id="imagesSelectionPrompt" className="my-5" />
        <Translate id="enableLocationCopy" className="mb-5" />
        <Translate id="imageTypesAllowed" />
      </div>

      {displayHistoricalImages && <ThumbnailImages historicalImages={historicalImages} />}

      {/* Hidden input element that will be triggered when user clicks the upload image box */}
      <input
        id="fileInput"
        type="file"
        accept=".png, .jpg, .jpeg, .heic"
        aria-label="Image Upload Input"
        className="h-0 opacity-0"
        onChange={handleChange}
      />

      <button
        onClick={() => document.getElementById('fileInput').click()}
        onDragEnter={handleDrag}
        onSubmit={e => e.preventDefault()}
        onDrop={handleDrop}
        className={uploadImageBox}
      >
        <img src={imageUploadIcon} alt="Upload Icon" />
        <Translate id="selectImage" className="font-light normal-case text-rain" />
      </button>
      {/* The drag and drop functionality will work for the whole page, so users don't necessarily need to "target" the box */}
      {dragActive && (
        <div
          className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}

      {imagesArray.map(image => (
        <ProgressBar
          imageName={image.imageName}
          key={image.imageName}
          isLoaded={image.isLoaded}
          setImagesArray={setImagesArray}
        />
      ))}

      <div className="my-10 flex flex-row gap-4 md:gap-10">
        <Button
          onClick={cancelUpload}
          className={displayHistoricalImages ? uploadCompleted : invertedButtonStyle}
        >
          <Translate id={displayHistoricalImages ? 'uploadCompleted' : 'cancel'} />
        </Button>

        {/* Navigate to form page and pass the array of images as prop (can't pass some objects like the file to Redux) */}
        <Button
          onClick={() => navigate('/form', { state: { imagesArray } })}
          disabled={imagesArray.length === 0}
        >
          <Translate id="next" />
        </Button>
      </div>
    </div>
  );
};
