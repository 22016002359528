import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Translate } from './Translate';
import { LinkLikeButton } from './Button';
import checkIcon from '../../assets/icons/check-circle.svg';

export const ProgressBar = ({ imageName, isLoaded = false, setImagesArray }) => {
  const wrapper = 'flex flex-col items-center';
  const container =
    'w-80 md:w-96 h-10 relative border mb-5 rounded-full text-slate overflow-hidden shadow-medium';
  const progressBar = 'flex items-center w-full h-full absolute bg-fossil';
  const bar = clsx(
    'absolute h-full w-full rounded-full transition-[width] duration-[2000ms] ease-in-out',
    isLoaded ? 'bg-slate' : 'bg-sage'
  );
  const textContainer = clsx(
    'z-10 ml-6 flex h-6 w-64 gap-1.5 overflow-hidden truncate text-ice',
    isLoaded && 'ml-4 !gap-2.5'
  );

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoaded) {
      setProgress(100);
    } else {
      const id = setInterval(() => {
        setProgress(Math.random() * 100);
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [isLoaded]);

  return (
    <>
      {/* Remove button */}
      <LinkLikeButton
        className="mb-2 ml-56 md:ml-72"
        onClick={() => setImagesArray(prev => prev.filter(image => image.imageName !== imageName))}
      >
        {isLoaded && <Translate id="remove" />}
      </LinkLikeButton>

      {/* Progress Bar */}
      <div className={wrapper}>
        <div className={container}>
          <div className={progressBar}>
            <div
              className={bar}
              style={{
                width: `${progress}%`,
              }}
            />
            <div className={textContainer}>
              {isLoaded ? <img src={checkIcon} alt="Loading" /> : <Translate id="loading" />}
              {imageName}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
