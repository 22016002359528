export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const GRAY = '#737373';
export const ERROR = '#BD2D2D';

// Neutral gray gradient from https://tailwindcss.com/docs/customizing-colors
export const GRAY_GRADIENT = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#e5e5e5',
  300: '#d4d4d4',
  400: '#a3a3a3',
  500: GRAY,
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#171717',
  950: '#0a0a0a',
};

// BBW COLOR PALETTE
export const ICE = '#F0F3F4';
export const SAGE = '#87BCBF';
export const SLATE = '#6E8CA0';
export const RAIN = '#324755';
export const WHOOP = '#500000';
export const ONYX = '#1B1C20';
export const SUNSHINE = '#FCC931';
export const FOSSIL = '#C8D1D3';
export const SAND = '#B9B0A2';
export const SNOW = '#FFFFFF';

export const PRIMARY = RAIN;

// BBW COLOR PALETTE GRADIENTS
export const SAGE_GRADIENT = {
  50: '#f3f8f8',
  100: '#e7f1f2',
  200: '#cfe4e5',
  300: '#b7d7d9',
  400: '#9fc9cc',
  500: SAGE,
  600: '#6b9395',
  700: '#506c6e',
  800: '#364849',
  900: '#1e2627',
  950: '#131717',
};

export const colors = {
  white: {
    DEFAULT: WHITE,
  },
  black: {
    DEFAULT: BLACK,
  },
  error: {
    DEFAULT: ERROR,
  },
  fossil: {
    DEFAULT: FOSSIL,
  },
  gray: {
    DEFAULT: GRAY,
    ...GRAY_GRADIENT,
  },
  ice: {
    DEFAULT: ICE,
    dark: '#d2d5d6',
  },
  onyx: {
    DEFAULT: ONYX,
  },
  primary: {
    DEFAULT: PRIMARY,
    dark: '#06624A',
  },
  rain: {
    DEFAULT: RAIN,
    light: '#4b606f',
  },
  sage: {
    DEFAULT: SAGE,
    ...SAGE_GRADIENT,
  },
  sand: {
    DEFAULT: SAND,
  },
  slate: {
    DEFAULT: SLATE,
  },
  snow: {
    DEFAULT: SNOW,
  },
  sunshine: {
    DEFAULT: SUNSHINE,
  },
  whoop: {
    DEFAULT: WHOOP,
    200: '#EFE7E7',
  },
};
