export const statusIsGood = status => status >= 200 && status < 300;
export const statusIsUnauthorized = status => status === 401;

export const stringIsEmptyOrNull = str => str == null || str === '';

export const getRandomInt = max => Math.floor(Math.random() * max);

export const makeRandomId = length => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Takes a longitude or latitude measurement of degrees, minutes, seconds, and direction and converts it to decimal degrees
// Source: https://awik.io/extract-gps-location-exif-data-photos-using-javascript/
export const convertDMStoDecimal = (gps, direction) => {
  const degrees = gps[0].numerator / gps[0].denominator;
  const minutes = gps[1].numerator / gps[1].denominator;
  const seconds = gps[2].numerator / gps[2].denominator;

  const dd = degrees + minutes / 60 + seconds / 3600;
  if (direction === 'S' || direction === 'W') {
    return dd * -1;
  }
  return dd;
};

// Converts a date time string to a date in YYYY-MM-DD format taking into account the timezone offset
export const getDateFromDateTime = datetime => {
  const offset = datetime.getTimezoneOffset();
  const date = new Date(datetime.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
};

// Gets the hour, minutes and AM/PM from a date time string
export const getTimeFromDateTime = datetime => {
  const newDate = new Date(datetime);
  const hours = newDate.getHours() > 12 ? newDate.getHours() - 12 : newDate.getHours();
  const minutes = newDate.getMinutes();
  const amPM = newDate.getHours() >= 12 ? 'PM' : 'AM';

  return [hours, minutes, amPM];
};

// Converts a date time string to a date in Central Time
export const getDateTimeInCentralTime = (date, hour, minutes, amPM) => {
  return new Date(`${date} ${hour}:${minutes} ${amPM}`).toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  });
};
