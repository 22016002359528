export const ThumbnailImages = historicalImages => {
  // Select the three most recent images from array
  const imagesArray = historicalImages.historicalImages;
  const selectedImages =
    imagesArray.length > 3 ? [...imagesArray].slice(-3).reverse() : [...imagesArray].reverse();

  return (
    <div className="mt-5 flex h-auto flex-col flex-wrap justify-center gap-5 md:flex-row md:gap-10">
      {selectedImages.map(image => (
        <img
          src={image.url}
          key={image.name}
          alt="Previous Submitted"
          className="h-auto w-80 md:max-h-full md:max-w-full md:object-cover md:align-bottom"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `https://placehold.co/300?text=${image.name}+is+unavailable`;
            currentTarget.alt = 'Image unavailable';
          }}
        />
      ))}
    </div>
  );
};
