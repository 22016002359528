import { Translate } from './general/Translate';
import idrtFullLogo from '../assets/idrt-full-logo.svg';

export const About = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={idrtFullLogo} alt="IDRT Logo" height="100" width="600" className="mb-6 mt-10" />
      <Translate id="sitePurposeCopy" className="mb-6 w-4/5 md:w-1/2" />
      <Translate id="dripMissionCopy" className="mb-6 w-4/5 md:w-1/2" />
      <a
        href="https://idrt.tamug.edu/drip/"
        target="_blank"
        rel="noreferrer"
        className="w-4/5 text-slate underline underline-offset-4 md:w-1/2"
      >
        <Translate id="learnMore" />
      </a>
    </div>
  );
};
