import { useDispatch, useSelector } from 'react-redux';
import { setAboutUsModal } from '../actions/modals';
import { showAboutUsModalSelector } from '../selectors/modals';
import { Modal } from './general/Modal';
import { Translate } from './general/Translate';
import idrtFullLogo from '../assets/idrt-full-logo.svg';

export const AboutUsModal = () => {
  const showModal = useSelector(showAboutUsModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setAboutUsModal(false));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      closeOnOuterClick={true}
      onClose={onClose}
      style={{
        width: 'min(95%, 550px)',
        backgroundRepeat: 'repeat-x',
      }}
    >
      <img src={idrtFullLogo} alt="IDRT Logo" className="mb-4 ml-[-15px] mt-2" />
      <Translate id="sitePurposeCopy" className="mb-6" />
      <Translate id="dripMissionCopy" className="mb-6" />
      <a
        href="https://idrt.tamug.edu/drip/"
        target="_blank"
        rel="noreferrer"
        className="text-slate underline underline-offset-4"
      >
        <Translate id="learnMore" />
      </a>
    </Modal>
  );
};
