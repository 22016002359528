import { SET_HISTORICAL_IMAGES } from '../actions/general';

const defaultState = {
  showHistoricalImages: [],
};

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HISTORICAL_IMAGES:
      return {
        ...state,
        showHistoricalImages: [...state.showHistoricalImages, ...action.payload],
      };
    default:
      return state;
  }
};

export default generalReducer;
