import clsx from 'clsx';
import '../../style/loaders.css';

const getButtonStyles = ({ className, disabled, isLoading }) =>
  clsx(
    'mb-4 h-16 w-32 cursor-pointer rounded-md bg-rain text-center font-condensed text-lg font-bold uppercase text-ice drop-shadow-md hover:bg-rain-light md:w-40 md:text-xl',
    (disabled || isLoading) && 'pointer-events-none cursor-auto opacity-50',
    className
  );

/**
 * Simple button component, pass tailwind class properties as className to override default styles
 * @param {{
 *   className?: string,
 *   disabled?: boolean,
 *   isLoading?:boolean,
 * }} props
 */
export const Button = (props = { disabled: false, isLoading: false }) => {
  const { className, isLoading, ...restOfProps } = props;
  return (
    <button className={getButtonStyles(props)} type="button" {...restOfProps}>
      {props.isLoading ? <ButtonLoader /> : props.children}
    </button>
  );
};

/**
 * Button that is styled to look like a link, pass tailwind class properties as className to override default styles
 * @param {*} props
 */
export const LinkLikeButton = (props = {}) => {
  const { className, ...restOfProps } = props;
  return (
    <button
      className={clsx(
        'bg-transparent m-0 cursor-pointer border-0 p-0 font-condensed text-base font-bold text-rain underline',
        className
      )}
      {...restOfProps}
    />
  );
};

// Button loader, with animated dots in a line
const ButtonLoader = () => (
  <div className="lds-ellipsis" data-testid="button-loader">
    <div />
    <div />
    <div />
    <div />
  </div>
);
