import { Translate } from './components/general/Translate';

export const validateMandatoryFields = values => {
  const errors = {};

  if (values.locationCoordinates.length === 0) {
    errors.locationCoordinates = <Translate id="required" />;
  }
  if (!values.date) {
    errors.date = <Translate id="required" />;
  }
  if (values.hour < 1 || values.hour > 12 || !Number.isInteger(values.hour)) {
    errors.hour = <Translate id="hourFormat" />;
  }
  if (values.minute < 0 || values.minute > 59 || !Number.isInteger(values.minute)) {
    errors.minute = <Translate id="minuteFormat" />;
  }
  // Not required but if users enter them, they must follow the indicated format
  if (values.floodDepth < 0 || (values.floodDepth !== '' && !Number.isInteger(values.floodDepth))) {
    errors.floodDepth = <Translate id="wholeNumberFormat" />;
  }
  // Not required but if users enter them, they must follow the indicated format
  if (
    values.floodDamage < 0 ||
    (values.floodDamage !== '' && !Number.isInteger(values.floodDamage))
  ) {
    errors.floodDamage = <Translate id="wholeNumberFormat" />;
  }
  if (!values.appearanceRelease) {
    errors.appearanceRelease = <Translate id="required" />;
  }
  return errors;
};
