import { Link } from 'react-router-dom';
import { Translate } from './general/Translate';

import dripLogo from '../assets/drip-logo.svg';

export const Header = () => {
  return (
    <header>
      <div
        className={`h-18 z-10 flex w-full items-center justify-between border-b border-gray-300 bg-rain py-1 shadow`}
      >
        <div className="flex w-full items-center justify-between px-12">
          <Link to="/" className="flex items-center">
            <img className="mr-2 w-14" src={dripLogo} alt="DRIP Logo" />
            <h1 className="font-condensed text-2xl text-ice">Community Driven Flood Map</h1>
          </Link>
          <Link
            to="/about"
            target="_blank"
            className="text-lg text-ice underline underline-offset-4"
          >
            <Translate id="about" />
          </Link>
        </div>
      </div>
    </header>
  );
};
