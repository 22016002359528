import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';

import { AboutUsModal } from './AboutUsModal';
import { Header } from './Header';
import { Landing } from './Landing';
import { About } from './About';
import { Upload } from './Upload';
import { Form } from './Form';
import { Error } from './Error';

export const Main = () => {
  const { pathname } = useLocation();
  const inLandingPage = pathname === '/';

  const [mobileView, setMobileView] = useState(window.innerWidth < 850);

  window.addEventListener('resize', () => {
    setMobileView(window.innerWidth < 850);
  });

  return (
    <div className="w-full">
      <AboutUsModal />
      <ToastContainer autoClose={3000} position="bottom-right" transition={Flip} hideProgressBar />

      {/* The header will only be displayed on desktop view on all pages except the landing page */}
      {!mobileView && !inLandingPage && <Header />}

      <main>
        <Routes>
          <Route path="/" exact={true} element={<Landing />} />
          <Route path="about" exact={true} element={<About />} />
          <Route path="upload" exact={true} element={<Upload />} />
          <Route path="form" exact={true} element={<Form />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </main>
    </div>
  );
};
