import FocusTrap from 'focus-trap-react';
import { useEffect } from 'react';

export const Modal = ({ children, onClose, style, wrapperStyle, closeOnOuterClick }) => {
  useEffect(() => {
    const closeOnEscapePress = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', closeOnEscapePress);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
    };
  }, [onClose]);

  return (
    <div
      className="bg-[rgba(100, 100, 100, 0.45)] fixed top-0 z-[1000] flex h-full w-full items-center justify-center"
      style={wrapperStyle}
      onClick={() => (closeOnOuterClick ? onClose() : null)}
    >
      <FocusTrap>
        <div
          className="md:width-[85%] bg-white relative max-h-[85%] w-[95%] shrink-0 overflow-x-auto overflow-y-scroll rounded-lg px-8 py-10 shadow-md"
          style={style}
          onClick={e => e.stopPropagation()}
        >
          <button
            aria-label="close"
            className="absolute right-4 top-2 flex cursor-pointer items-center justify-end font-bold text-primary"
            type="button"
            onClick={onClose}
          >
            &#10005;
          </button>
          {children}
        </div>
      </FocusTrap>
    </div>
  );
};
