import clsx from 'clsx';
import { Field } from 'formik';
import { Translate } from './general/Translate';

export const multiFieldLine =
  'flex flex-col items-start justify-start md:flex-row md:items-center md:justify-center md:gap-8';

const inputBoxStyle =
  'w-80 md:w-[500px] rounded-none border border-rain [&>*]:rounded-none focus:rounded-none';

// Wrapper for field inputs, displaying labels and helper text
export const FieldWrapper = ({
  label,
  children,
  error,
  additionalLabelStyling,
  additionalInputBoxStyling,
  hideLabel,
  id,
}) => {
  const labelStyle = clsx('self-start text-rain', additionalLabelStyling);
  const errorMessageStyle = 'self-start text-error italic';
  const fieldWrapperInputBoxStyle = clsx(
    'my-1 [&>input]:w-80 [&>input]:rounded-none [&>input]:border [&>input]:border-rain md:[&>input]:w-[500px]',
    additionalInputBoxStyling
  );

  return (
    <div className="mb-4 mt-1.5 flex flex-col items-center justify-center">
      {!hideLabel && (
        <label htmlFor={id} className={clsx(labelStyle, additionalLabelStyling)}>
          <Translate id={label} />
        </label>
      )}
      <div className={fieldWrapperInputBoxStyle}>{children}</div>
      {error && (
        <label id={`errorMsg_${id}`} className={errorMessageStyle}>
          {error}
        </label>
      )}
    </div>
  );
};

export const TextField = ({ name, placeholder, type, min, max, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      name={name}
      type={type}
      min={min}
      max={max}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
);

export const AppearanceReleaseField = ({ name, message, type, min, max, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <div className="mb-4 h-80 w-80 overflow-auto border border-rain bg-white md:w-[500px] [&>*]:my-4 [&>*]:px-4">
      <Translate id="releaseForm1" />
      <Translate id="releaseForm2" />
      <Translate id="releaseForm3" />
      <Translate id="releaseForm4" />
      <Translate id="releaseForm5" />
    </div>
    <label className="flex flex-row items-center gap-2">
      <Field
        id={name}
        name={name}
        type="checkbox"
        aria-describedby={`errorMsg_${name}`}
        aria-invalid={props.error ? 'true' : 'false'}
      />
      <Translate id="acknowledge" />
    </label>
  </FieldWrapper>
);

export const SearchField = ({ name, placeholder, inputRef, onChange, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <input
      id={name}
      type="search"
      name={name}
      ref={inputRef}
      onChange={onChange}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
      className="bg-search-icon bg-scroll bg-[10px] bg-no-repeat indent-5"
    />
  </FieldWrapper>
);

export const ParagraphTextField = ({
  name,
  placeholder,
  maxCharacters,
  additionalStyling,
  ...props
}) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      as="textarea"
      name={name}
      placeholder={placeholder}
      maxLength={maxCharacters}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
      className={clsx(inputBoxStyle, additionalStyling)}
    />
  </FieldWrapper>
);

export const SelectField = ({ name, children, placeholder, additionalStyling, ...props }) => (
  <FieldWrapper {...props}>
    <Field
      id={name}
      as="select"
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
      className={clsx(inputBoxStyle, additionalStyling)}
    >
      {children}
    </Field>
  </FieldWrapper>
);

export const OptionsList = ({ options, name, activeLanguageCode }) => {
  return options.map(option => (
    <option
      key={`${name}_${option['en']}`}
      value={option[activeLanguageCode]}
      disabled={option['en'] === 'Select' || option['es'] === 'Selecciona'}
    >
      {option[activeLanguageCode]}
    </option>
  ));
};
