import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/general/ScrollToTop';
import { Main } from './components/Main';
import store from './store';

import 'react-toastify/dist/ReactToastify.css';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Main />} />
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
