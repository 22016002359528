import { Translate } from '../components/general/Translate';
import { SLATE } from './colors';

// Collection of ids (for translation) and options (for dropdowns) used in the form page
export const LOCATION_ID = 'location';
export const DATE_ID = 'date';
export const HOUR_ID = 'hour';
export const MINUTE_ID = 'minute';
export const AM_PM_ID = 'isAM';
export const ASSET_TYPES_ID = 'assetType';
export const DAMAGE_TYPES_ID = 'damageType';
export const DAMAGE_ADDITIONAL_INFO_ID = 'damageAdditionalInfo';
export const FLOOD_DEPTH_ID = 'floodDepth';
export const FLOOD_DAMAGE_ID = 'floodDamage';
export const HAS_FLOODED_BEFORE_ID = 'hasFloodedBefore';
export const FLOOD_EVENT_DESCRIPTION_ID = 'floodEventDescription';
export const APPEARANCE_RELEASE = 'appearanceRelease';

export const AM_PM_OPTIONS = [
  { en: 'AM', es: 'AM' },
  { en: 'PM', es: 'PM' },
];

export const ASSET_TYPES_OPTIONS = [
  { en: 'Select', es: 'Selecciona' },
  { en: 'Personal', es: 'Personal' },
  { en: 'Public', es: 'Público' },
  { en: 'Commercial', es: 'Comercial' },
];

export const MULTISELECT_STYLE = {
  control: base => ({
    ...base,
    minWidth: '320px',
    borderRadius: 0,
    borderColor: '#000',
    color: '#000',
    '&:hover': {
      borderColor: '#000',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#000',
    border: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#000',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px',
    marginLeft: '15px',
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: SLATE,
    color: '#fff',
    margin: '5px 2px',
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#fff',
  }),
};

export const DAMAGE_TYPES_OPTIONS = [
  { value: 'Property', label: <Translate id="property" /> },
  { value: 'Health Impacts', label: <Translate id="healthImpacts" /> },
  { value: 'Loss of Income', label: <Translate id="incomeLoss" /> },
  { value: 'Road Damage', label: <Translate id="roadDamage" /> },
  { value: 'Critical Services', label: <Translate id="criticalServices" /> },
  { value: 'Crop/Livestock', label: <Translate id="crop" /> },
  { value: 'Land Damage', label: <Translate id="landDamage" /> },
];

export const HAS_FLOODED_BEFORE_OPTIONS = [
  { en: 'Select', es: 'Selecciona' },
  { en: 'Yes', es: 'Sí' },
  { en: 'No', es: 'No' },
  { en: 'Unknown', es: 'Desconocido' },
];
