import { 
  SET_LANGUAGE_CODE,
} from '../actions/translations';
import idToSpanish from '../translations/es.json'
import idToEnglish from '../translations/en.json'

const defaultState = {
  languageCode: 'en',
  idToText: idToEnglish,
}

const translationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_CODE:
      switch (action.payload) {
        case 'en':
          return {
            languageCode: 'en',
            idToText: idToEnglish
          };
        case 'es':
          return {
            languageCode: 'es',
            idToText: idToSpanish
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default translationsReducer;